import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { Suspense, lazy, useState, useEffect, useContext } from 'react';
import TagManager from 'react-gtm-module';

import Context from './Context';


import ScrollToTop from "./Components/ScrollToTop";

import Header from "./Components/Header";
import Footer from "./Components/Footer";
import axios from "axios";

import { numbersOnly } from "./Utils";
import Whatsapp from "./Assets/images/icons/whatsapp.svg";

const Cookies = lazy(() => import('./Components/Cookies'));

const Home = lazy(() => import('./Pages/Home'));
const Empresa = lazy(() => import('./Pages/Empresa'));
const Cases = lazy(() => import('./Pages/Cases'));
const Case = lazy(() => import('./Pages/Case'));
const Equipamentos = lazy(() => import('./Pages/Equipamentos'));
const Equipamento = lazy(() => import('./Pages/Equipamento'));
const Videos = lazy(() => import('./Pages/Videos'));
const Video = lazy(() => import('./Pages/Video'));
const Orcamento = lazy(() => import('./Pages/Orcamento'));
const Texto = lazy(() => import('./Pages/Texto'));
const NotFound = lazy(() => import('./Pages/NotFound'));


function App() {
  localStorage.setItem("apiUrl", "https://lidermaquinas.com.br/sistema/api/");
  // localStorage.setItem("apiUrl", "https://lidermaquinas.dev.fw2propaganda.com.br/sistema/api/");
  // localStorage.setItem("apiUrl", "http://localhost:8080/api/");
  const [resumo, setResumo] = useState({});
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(false);
  const value = { resumo, setResumo, modal, setModal, activePage, setActivePage };

  useEffect(() => {
    axios.get(localStorage.getItem("apiUrl") + "configuracoes").then(response => {
      setResumo(response.data.success);
      if (response.data.success.configuracoes.tag_manager) {
        if (response.data.success.configuracoes.tag_manager.length > 5) {
          const tagManagerArgs = {
            gtmId: response.data.success.configuracoes.tag_manager
          }
          let checkCookies = setInterval(() => {
            let accept = localStorage.getItem("fw2AcceptCookiesDesnecessarios");
            if (accept) {
              TagManager.initialize(tagManagerArgs)
              clearInterval(checkCookies);
            }
          }, 3000);
        }
      }
    });
  }, []);

  return (
    <HelmetProvider>
      <Context.Provider value={value}>
        <div id="modal" className={modal ? "active" : ""}>
          {modal && <div className="content">
            <h3>{modal.titulo}</h3>
            <p>{modal.texto}</p>
            <button type="button" onClick={() => { if (modal.onExit) { modal.onExit() } setModal(false) }}>{modal.botao}</button>
          </div>}
        </div>
        <Router>
          <Switch>
            <Route path="*">
              <Application />
            </Route>
          </Switch>
        </Router>
      </Context.Provider>
    </HelmetProvider>
  );
}

const Application = () => {
  const { resumo } = useContext(Context);
  let location = useLocation();
  return (
    <div className="App">
      <ScrollToTop />
      {resumo.configuracoes &&
        <>
          <Header />
          <Suspense fallback={<div className="suspense"></div>}>
            <TransitionGroup>
              <CSSTransition key={location.key} timeout={300} classNames="fade" mountOnEnter={true} unmountOnExit={true}>
                <Switch location={location}>
                  <Route path="/" exact>
                    <Home />
                  </Route>
                  <Route path="/empresa" exact>
                    <Empresa />
                  </Route>
                  <Route path="/equipamentos" exact>
                    <Equipamentos />
                  </Route>
                  <Route path="/equipamentos/:slug" exact>
                    <Equipamentos />
                  </Route>
                  <Route path="/equipamento/:slug" exact>
                    <Equipamento />
                  </Route>
                  <Route path="/cases" exact>
                    <Cases />
                  </Route>
                  <Route path="/case/:slug" exact>
                    <Case />
                  </Route>
                  <Route path="/videos" exact>
                    <Videos />
                  </Route>
                  <Route path="/video/:slug" exact>
                    <Video />
                  </Route>
                  <Route path="/solicite-um-orcamento" exact>
                    <Orcamento />
                  </Route>
                  {resumo.textos &&
                    <Route path="/termos-de-uso" exact>
                      <Texto titulo={"Termos de uso"} texto={resumo.textos.termos_de_uso} />
                    </Route>
                  }
                  {resumo.textos &&
                    <Route path="/politica-de-privacidade" exact>
                      <Texto titulo={"Política de privacidade"} texto={resumo.textos.politica_de_privacidade} />
                    </Route>
                  }
                  <Route component={NotFound} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
            <Cookies />
          </Suspense>
          <Footer />
        </>
      }
      {
        resumo.configuracoes && <div itemScope itemType="https://schema.org/LocalBusiness" className="schema">
          <span itemProp="name">{resumo.configuracoes.app_title}</span>
          {resumo.seo.empresa && <span itemProp="image">{resumo.seo.empresa.imagem}</span>}
          <div itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
            <span itemProp="streetAddress">{resumo.configuracoes.endereco}</span>
            <span itemProp="addressLocality">{resumo.configuracoes.cidade}</span>,
            <span itemProp="addressRegion">{resumo.configuracoes.estado}</span> <span itemProp="postalCode">{resumo.configuracoes.cep}</span>
          </div>
          <span itemProp="telephone">{resumo.configuracoes.telefone}</span>
          <a itemProp="url" href={resumo.configuracoes.url}>{resumo.configuracoes.url}</a>
          <meta itemProp="openingHours" content={resumo.configuracoes.horario} />
        </div>
      }
      {resumo.configuracoes && resumo.configuracoes.whatsapp.length > 8 && <a href={`https://wa.me/55${numbersOnly(resumo.configuracoes.whatsapp)}?text=${encodeURIComponent(resumo.configuracoes.whatsappTexto)}`} target="_blank" rel="noreferrer noopener" className="floating-whatsapp" title="Fale conosco">
        <img src={Whatsapp} width="48px" height="48px" alt="whatsapp" />
      </a>}
    </div >
  )
}

export default App;
