import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Menu from "../../Assets/images/icons/menu.svg";
import Close from "../../Assets/images/icons/close.svg";
import Whatsapp from "../../Assets/images/icons/whatsapp2.svg";
import Context from "../../Context"
import "./index.scss";

const Header = () => {
    const { resumo } = useContext(Context);
    const [menu, setMenu] = useState(false);

    return (
        <div id="header">
            <div className="container">
                <Link to="/" title="Home"><img src={`${localStorage.getItem("apiUrl")}../public_images/logo.svg`} alt="Líder Máquinas" /></Link>
                <button className="toggleMenu" type="button" onClick={() => setMenu(!menu)}><img src={menu ? Close : Menu} alt="Menu" /></button>
                <div className={`menu ${menu ? "active" : ""}`}>
                    <Link to="/" title="Home">Home</Link>
                    <Link to="/empresa" title="A Líder Máquinas">A Líder Máquinas</Link>
                    <Link to="/equipamentos" title="Equipamentos">Equipamentos</Link>
                    <Link to="/cases" title="Cases de sucesso">Cases de sucesso</Link>
                    <Link to="/videos" title="Vídeos">Vídeos</Link>
                    <Link to="/solicite-um-orcamento" title="Solicite um orçamento">Solicite um orçamento <img src={Whatsapp} alt="Whatsapp" /></Link>
                </div>
            </div>
        </div>
    )
}

export default Header;