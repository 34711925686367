import "./index.scss";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Facebook from "../../Assets/images/icons/facebook.svg";
import Instagram from "../../Assets/images/icons/instagram.svg";
import Linkedin from "../../Assets/images/icons/linkedin.svg";
import BNDES from "../../Assets/images/bndes.svg";
import Context from "../../Context";

const Footer = () => {
    const { resumo } = useContext(Context);
    const [categorias, setCategorias] = useState([]);
    
    useEffect(() => {
        let categorias = [];
        resumo.categorias.some(categoria => {
            categorias.push(categoria);
            if (categorias.length >= 6) {
                return true;
            } else {
                return false;
            }
        });

        setCategorias(categorias)

    }, [resumo]);

    return (
        <div id="footer">
            <div className="menus">
                <div className="container">
                    <div className="row">
                        <div className="col-3">
                            <Link to="/"><img src={resumo.imagens.logo_rodape} alt="Logo" /></Link>
                            <p dangerouslySetInnerHTML={{ __html: resumo.textos.rodape_resumo }}></p>
                            {resumo.configuracoes &&
                                <div className="socials">
                                    {resumo.configuracoes.facebook.length > 5 && <a href={resumo.configuracoes.facebook} target="_blank" title="Facebook" rel="noopener noreferrer"><img src={Facebook} alt="Facebook" /></a>}
                                    {resumo.configuracoes.instagram.length > 5 && <a href={resumo.configuracoes.instagram} target="_blank" title="Instagram" rel="noopener noreferrer"><img src={Instagram} alt="Instagram" /></a>}
                                    {resumo.configuracoes.linkedin.length > 5 && <a href={resumo.configuracoes.linkedin} target="_blank" title="Linkedin" rel="noopener noreferrer"><img src={Linkedin} alt="Linkedin" /></a>}
                                </div>
                            }
                            <div className="bndes">
                                <img src={BNDES} alt="BNDES" />
                                <p dangerouslySetInnerHTML={{ __html: resumo.textos.bndes }}></p>
                                {resumo.configuracoes.bndes && resumo.configuracoes.bndes.length > 5 && <a href={resumo.configuracoes.bndes} target="_blank" title="bndes" rel="noopener noreferrer">Faça uma simulação</a>}
                            </div>
                        </div>
                        <div className="col-3"></div>
                        <div className="col-2">
                            <p className="title">Links Úteis</p>
                            <div className="menu">
                                <Link to="/">Home</Link>
                                <Link to="/empresa">A Líder Máquinas</Link>
                                <Link to="/equipamentos">Equipamentos</Link>
                                <Link to="/cases">Cases</Link>
                                <Link to="/videos">Vídeos</Link>
                                <Link to="/solicite-um-orcamento">Solicitar Orçamento</Link>
                            </div>
                        </div>
                        <div className="col-2">
                            <p className="title">Equipamentos</p>
                            <div className="menu">
                                {resumo.equipamentos && resumo.equipamentos.map(equipamento => <Link to={`/equipamento/${equipamento.slug}`} key={`equipamentos-rodape-${equipamento.id}`}>{equipamento.nome}</Link>)}
                            </div>
                        </div>
                        <div className="col-2">
                            <p className="title">Categorias</p>
                            <div className="menu">
                                {categorias && categorias.map(categoria => <Link to={`/equipamentos/${categoria.slug}`} key={`categorias-rodape-${categoria.id}`}>{categoria.nome}</Link>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="creditos">
                <div className="container">
                    {resumo.configuracoes && <p>Líder Máquinas | {resumo.configuracoes.endereco} - {resumo.configuracoes.bairro} - {resumo.configuracoes.cidade} - {resumo.configuracoes.estado}, {resumo.configuracoes.cep}</p>}
                    <p>Desenvoldido por <a href={"https://fw2propaganda.com.br/"} target="_blank" title="Desenvolvido por Gustavo Barbosa" rel="noopener noreferrer">FW2 Propaganda</a></p>
                </div>
            </div>
        </div>
    )
}

export default Footer;